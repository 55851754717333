<template>
  <base-list-view
    :selectedItems="selectedItems"
    :dataSource="value"
    :headers="headers"
    isNested
    :header-hidden="!isSelect"
    :hide-default-footer="!isSelect"
    v-on="$listeners"
    v-bind="$attrs"
    :notShowSelect="!isSelect"
    ref="listView"
    textCustomAdd="Добавить документ"
    @customHandle="hadlerClick"
    :item-class="
      (item) => {
        return item.Status === 6 ? 'disabled' : '';
      }
    "
    :custom-filter="customFilter"
    :show-add-in-table="[
      { text: 'Добавить локальный документ', key: 'document' },
    ]"
    @customAdd="customAdd"
    :show-actions="!readonly"
    editable
    :hideEdit="true"
    :hideDelete="(e) => e.Objects && !e.Objects.find((e) => e.IsCurrent)"
  >
    <template slot="item.Objects" slot-scope="{ item }">
      <div v-for="elem in item.Objects" :key="elem.Id">
        <a
          href="#"
          @click.stop="handleClickObject($event, elem)"
          :title="elem.Name"
        >
          {{ elem.Type | getEnums("DocumentAttachedDataType") }}</a
        >
      </div>
    </template>
    <popup-document
      v-if="$attrs['show-add-in-table']"
      v-model="showDialog"
      :show-add-in-table="false"
      @save="save"
      :selectedItems="value"
    ></popup-document>
    <popup-template
      v-if="$attrs['show-add-in-table']"
      v-model="showDialogTemplate"
      :show-add-in-table="false"
      @save="save"
      :selectedItems="value"
    ></popup-template>
  </base-list-view>
</template>
<script>
import { OrganizationDocumentStatus } from "@/data/enums";
import Permissions from "@/data/permissions";

export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    PopupDocument: () => import("./PopupDocument.vue"),
    PopupTemplate: () => import("./PopupTemplate.vue"),
  },
  props: {
    selectedItems: { Array, default: () => [] },
    value: {
      type: Array,
      default: () => [],
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    customFilter: {
      type: Function,
      default: null,
    },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      showDialog: false,
      showDialogTemplate: false,
      employees: [],
      headers: [
        { text: "Наименование", value: "Name", notGroup: true },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: OrganizationDocumentStatus,
          align: "center",
          width: "30%",
        },
        {
          text: "Объект",
          value: "Objects",
          dataType: "array",
          align: "center",
          displayText(e) {
            return e.Name;
          },
          width: "20%",
          cellClass: "td-minimum text-no-wrap",
        },
      ],
    };
  },
  computed: {
    Permissions() {
      return Permissions;
    },
  },
  async mounted() {
    // this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    customAdd(key) {
      switch (key) {
        case "document":
          this.showDialog = true;
          break;
        case "template":
          this.showDialogTemplate = true;
          break;
      }
    },
    save(items) {
      for (let i = 0; i < items.length; i++) {
        if (!this.value.find((e) => e.Id === items[i].Id))
          this.value.push({ ...items[i], isNew: true });
      }
      this.showDialog = false;
      this.showDialogTemplate = false;
    },
    hadlerClick(e) {
      if (e.Status !== -1)
        this.$router.push({
          name: "LocalDocumentEdit",
          params: { objectId: e.Id },
        });
      else
        this.$router.push({
          name: "DocumentTemplateEdit",
          params: { objectId: e.Id, type: "sample" },
        });
    },
    handleClickObject(e, item) {
      e.preventDefault();
      let p = "";
      switch (item.Type) {
        case 1:
          p = "AuditIndicatorCategoriesEdit";
          break;
        case 2:
          p = "AuditIndicatorEdit";
          break;
        case 3:
          p = "CheckListEdit";
          break;
        case 4:
          p = "CheckListEdit";
          break;
      }
      this.$router.push({
        name: p,
        params: { objectId: item.ObjectId },
      });
    },
  },
};
</script>
